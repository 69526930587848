<template>
  <div class="page-wrapper">
    <h1>Gestion des succursales</h1>

    <h2>Liste des succursales</h2>
    <form @submit.prevent>
      <input v-model="search" type="text" placeholder="Rechercher une succursale" @keyup="filterLocationsList" />
    </form>

    <v-table
      v-if="filteredLocationsList"
      class="table table-striped table-bordered"
      cellspacing="0"
      cellpadding="0"
      :data="filteredLocationsList"
    >
      <thead slot="head">
        <v-th sort-key="name">Nom de la succursale</v-th>
        <v-th sort-key="category">Catégorie</v-th>
        <v-th sort-key="nbUsers">Nombre d'utilisateur</v-th>
        <th>&nbsp;</th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.id">
          <td>{{ row.name }}</td>
          <td>{{ row.category }}</td>
          <td>{{ row.nbUsers }}</td>
          <td>
            <button @click.prevent="editLocation($event, row)">🖊</button>
            <button v-if="row.role !== 'admin'" @click.prevent="deleteLocation($event, row.id)">🗑</button>
          </td>
        </tr>
      </tbody>
    </v-table>

    <v-table
      v-else-if="locationsList"
      class="table table-striped table-bordered"
      cellspacing="0"
      cellpadding="0"
      :data="locationsList"
    >
      <thead slot="head">
        <v-th sort-key="name">Nom de la succursale</v-th>
        <v-th sort-key="category">Catégorie</v-th>
        <v-th sort-key="location">Nombre d'utilisateur</v-th>
        <th>&nbsp;</th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.id">
          <td>{{ row.name }}</td>
          <td>{{ row.category }}</td>
          <td>{{ row.nbUsers }}</td>
          <td>
            <button @click.prevent="editLocation($event, row)">🖊</button>
            <button v-if="row.role !== 'admin'" @click.prevent="deleteLocation($event, row.id)">🗑</button>
          </td>
        </tr>
      </tbody>
    </v-table>

    <h2>Ajout/Modification</h2>
    <!-- Auth UI -->
    <form @submit="saveLocation($event)">
      <div>
        <label for="name">Nom de la succursale</label>
        <input id="name" v-model="location.name" type="text" required />
      </div>

      <div>
        <label for="category">Catégorie</label>
        <select id="category" v-model="location.category" name="category" required>
          <option value="A" selected>A</option>
          <option value="B">B</option>
          <option value="C">C</option>
          <option value="D">D</option>
        </select>
      </div>

      <div>
        <button type="submit" class="bt" :class="{ 'is-loading': isLoading }">
          <div class="bt-text">
            <p v-if="mode === 'create'">Ajouter</p>
            <p v-else>Modifer</p>
          </div>
          <p class="loader">
            &nbsp;
            <svg class="main-loader" width="38" height="38" xmlns="http://www.w3.org/2000/svg" stroke="#EFF1F2">
              <g transform="translate(1 1)" stroke-width="2" fill="none" fill-rule="evenodd">
                <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </svg>
            &nbsp;
          </p>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LocationsDB from '@/firebase/locations-db'

export default {
  data: () => ({
    isLoading: false,
    location: {},
    locationsList: null,
    filteredLocationsList: null,
    mode: 'create',
    search: ''
  }),
  computed: {
    ...mapState('authentication', ['user'])
  },
  mounted() {
    this.resetForm()
    this.getLocationsList()
  },
  methods: {
    async saveLocation(e) {
      e.preventDefault()

      this.isLoading = true

      const location = { ...this.location }
      const locationsDB = new LocationsDB()
      console.log({ location, mode: this.mode })

      if (this.mode === 'create') {
        await locationsDB.create(location)
      } else {
        await locationsDB.update(location)
      }

      this.getLocationsList()

      this.resetForm()

      this.isLoading = false
      this.mode = 'create'
    },
    async editLocation(e, location) {
      this.isLoading = true
      const locationsDB = new LocationsDB()
      this.location = await locationsDB.read(location.id)
      this.mode = 'update'
      this.isLoading = false
    },
    async deleteLocation(e, locationId) {
      this.isLoading = true

      const userChoice = await this.$swal({
        title: 'Supprimer',
        text: `Confirmer la suppression.`,
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non'
      })

      if (!userChoice.isConfirmed) {
        this.isLoading = false
        return
      }

      const locationsDB = new LocationsDB()
      await locationsDB.delete(locationId)
      this.getLocationsList()
      this.isLoading = false
    },
    async getLocationsList() {
      this.isLoading = true
      const locationsDB = new LocationsDB()
      this.locationsList = await locationsDB.readAll()
      this.isLoading = false
    },
    filterLocationsList() {
      if (!this.locationsList) {
        return
      }

      if (this.search && this.search.length >= 3) {
        this.filteredLocationsList = this.locationsList.filter(i => i.name.includes(this.search))
      } else {
        this.filteredLocationsList = null
      }
    },
    resetForm() {
      this.location = { nbUsers: 0 }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
